<template>
  <b-container>
    <b-form @submit.prevent="updateIdentityDetails">
      <h4>Identification</h4>
      <fieldset :disabled="!can('modify','suppliers')">
      <b-row>
        <b-col>
          <b-form-group label="Change Agent Photo" label-for="agent-photo">
            <b-form-file accept="image/*" v-model="new_photo" class="mt-3"> </b-form-file>
          </b-form-group>
        </b-col>
        <b-col>
          <b-img
            :blank="!photo"
            blank-color="#777"
            width="75"
            height="75"
            class="m1"
            :src="photo"
            rounded="circle"
            alt="Circle image"
          ></b-img>
        </b-col>
      </b-row>

      <b-form-group
        id="input-group-10"
        label="National ID Number"
        label-for="National Id"
      >
        <b-form-input
          type="text"
          v-model="editIdentityForm.va_id_number" 
         >
         </b-form-input>
      </b-form-group>
      <hr />

      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group label="National ID Image" label-for="agent-photo">
                <b-form-file accept="image/*" v-model="front" class="mt-3"> </b-form-file>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-img
                :blank="!id_front"
                blank-color="#777"
                width="200"
                height="75"
                class="m1"
                :src="id_front"
                rounded
                alt="Front"
              ></b-img>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <br/>
      <br/>

    <!-- submmit button -->
      <b-row>
        <b-col>
        <b-button type="submit" variant="secondary">
          <b-spinner v-if="isEditing" small label="Small Spinner"></b-spinner>
          Save Changes
        </b-button>
        </b-col>
      </b-row>
      </fieldset>
    </b-form>
  </b-container>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import { can } from '../../../../../../../../auth/utils';
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      editIdentityForm: {
        va_photo: null,
        va_id_photo: null,
        va_id_number: '',
      },
      new_photo: null,
      photo: null,
      id_front: null,
      front: null,
      isEditing: false,
      error: '',
      message:''
    };
  },
  methods: {
    can,
    setIdentityDetails(profile) {
      this.photo = 'https://storage.googleapis.com/ezyagric-media' + profile.va_photo;
      this.id_front = 'https://storage.googleapis.com/ezyagric-media' + profile.va_id_photo;
    },

    updateIdentityDetails() {
      this.isEditing = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      let vaId = this.item.vaId.replace(/\//g, '-');

      let formData = new FormData();
      if (this.editIdentityForm.va_photo) {
        formData.append('va_photo', this.editIdentityForm.va_photo, this.editIdentityForm.va_photo.name);
      }
      if (this.editIdentityForm.va_id_photo) {
        formData.append('va_id_photo', this.editIdentityForm.va_id_photo, this.editIdentityForm.va_id_photo.name);
      }
      
      formData.append('va_id_number', this.editIdentityForm.va_id_number)
      
      axiosApiInstance
        .put(baseUrl + `/api/v3/village-agents/identity-update/${vaId}`, 
        formData)
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.$emit('SuccesfulEdit', 'true');
            this.isEditing = false;
          } else if (!result.data.success) {
            this.isEditing = false;
            this.error = result.data.message;
            this.$vToastify.error("Something went wrong please try again");
          }
        })
        .catch(error => {
          this.isEditing = false;
          this.error = error;
          this.$vToastify.error("Something went wrong please try again");
        })
    },
  },
  mounted() {
    this.setIdentityDetails(this.item);
  },
  watch: {
    new_photo: {
      handler() {
        this.photo = URL.createObjectURL(this.new_photo);
        this.editIdentityForm.va_photo = this.new_photo;
      },
      deep: true,
    },
    front: {
      handler() {
        this.id_front = URL.createObjectURL(this.front);
        this.editIdentityForm.va_id_photo = this.front;
      },
      deep: true,
    },
  },
};
</script>
