<template>
  <b-form @submit.prevent="updateAgentServices">
    <h4>Services</h4>
    <fieldset :disabled="!can('modify','suppliers')">
    <b-form-group  v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        v-model="editServicesForm.services_va_provides"
        :options="services"
        id="checkboxes-4"
        :aria-describedby="ariaDescribedby"
      >
      </b-form-checkbox-group>
    </b-form-group>
    <b-row>
      <b-col>
        <b-button type="submit" variant="secondary" v-analytics:click="['agent_services_change',{element:'Suppliers'}]">
          <b-spinner v-if="isEditing" small label="Small Spinner"></b-spinner>
          Save Changes
        </b-button>
      </b-col>
    </b-row>
    </fieldset>
  </b-form>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import { can } from '../../../../../../../../auth/utils';
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      services: ["All services", "ProduceBulking", "Profiling", "Order Inputs"],
      editServicesForm: {
        services_va_provides: [],
      },
      isEditing: false,
      message: ''
    };
  },
  
  mounted() {
    this.setExistingServices(this.item);
  },

  methods: {
    can,
    setExistingServices(profile) {
      if (profile.services_va_provides) {
        let services = profile.services_va_provides.split(',');
        this.editServicesForm.services_va_provides = services;
      }
    },

    updateAgentServices() {
      this.isEditing = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      let vaId = this.item.vaId.replace(/\//g, '-');

      axiosApiInstance
        .put(baseUrl + `/api/v3/village-agents/services-update/${vaId}`, this.editServicesForm)
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.$emit('SuccesfulEdit', 'true');
            this.isEditing = false;
          } else if (!result.data.success) {
            this.isEditing = false;
            this.error = result.data.message;
            this.$vToastify.error("Something went wrong please try again");
          }
        })
        .catch(error => {
          this.isEditing = false;
          this.error = error;
          this.$vToastify.error("Something went wrong please try again");
        })
    },
  }
};
</script>
<style scoped>
*.custom-control-inline{
  display: block !important;
  width: 100%;
}
</style>