<template>
  <b-container>
    <b-form @submit.prevent="updateAgentBio">
      <h4>Bio Information</h4>
      <fieldset :disabled="!can('modify','suppliers')">
      <b-form-group id="input-group-1" label="Name" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="editAgentForm.va_name"
          type="text"
          required
        ></b-form-input>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group
            id="input-group-2"
            label="Date of birth"
            label-for="input-2"
          >
            <b-form-datepicker 
              v-model="editAgentForm.va_dob" 
              type="text" 
              required>
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-3" label="Gender" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="editAgentForm.va_gender"
              :options="gender"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        id="input-group-4"
        label="Phone Number"
        label-for="input-4"
      >
        <b-form-input
          id="input-4"
          v-model="editAgentForm.va_phonenumber"
          type="number"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Buying Agent">
        <b-form-select v-model="editAgentForm.buyer_id" :options="buyers">
          <template #first>
            <b-form-select-option :value="null" disabled>--Select Buying Agent If Applicable--</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group id="input-group-7" label="Region" label-for="input-7">
        <b-form-select
          id="input-7"
          v-model="editAgentForm.va_region"
          :options="regions"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-5" label="District" label-for="input-5">
        <b-form-select
          id="input-5"
          v-model="editAgentForm.va_district"
          :options="districts"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group6" label="Village" label-for="villa">
        <b-form-input
          id="villa"
          v-model="editAgentForm.va_village"
          type="text"
          required
        ></b-form-input>
      </b-form-group>

      <b-row>
        <b-col>
          <b-button type="submit" variant="secondary" v-analytics:click="['agent_bio_change',{element:'Suppliers'}]">
            <b-spinner v-if="isEditing" small label="Small Spinner"></b-spinner>
            Save Changes
          </b-button>
        </b-col>
      </b-row>
      </fieldset>
    </b-form>
  </b-container>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import { can } from '../../../../../../../../auth/utils';
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    buyers:{
      type:Array
    }
  },
  data() {
    return {
      gender: [{ text: "Select Gender", value: null }, "Male", "Female"],
      regions: [{ text: 'Select Region', value: null }, 'East', 'West', 'North', 'Central'],
      districts: [],
      editAgentForm: {
        va_name: '',
        va_dob: '',
        va_gender: null,
        va_phonenumber: '',
        va_district: null,
        va_village: '',
        va_region: '',
        buyer_id:null
      },
      isEditing: false,
      message: ''
    };
  },

  mounted() {
    this.setDistrictsToSelect();
    this.setBioDetails(this.item);
  },

  methods: {
    can,
     setBioDetails(profile) {
      this.editAgentForm.va_name = profile.va_name;
      this.editAgentForm.va_dob = profile.va_dob;
      this.editAgentForm.va_gender = profile.va_gender;
      this.editAgentForm.va_phonenumber = profile.va_phonenumber;
      this.editAgentForm.va_district = profile.va_district;
      this.editAgentForm.va_village = profile.va_village;
      this.editAgentForm.va_region = profile.va_region;
      this.editAgentForm.buyer_id = profile.buyer_id||null;
    },

    updateAgentBio() {
      this.isEditing = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      let vaId = this.item.vaId.replace(/\//g, '-');

      axiosApiInstance
        .put(baseUrl + `/api/v3/village-agents/bio-update/${vaId}`, this.editAgentForm)
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.$emit('SuccesfulEdit', 'true');
            this.isEditing = false;
          } else if (!result.data.success) {
            this.isEditing = false;
            this.error = result.data.message;
            this.$vToastify.error("Something went wrong please try again");
          }
        })
        .catch(error => {
          this.isEditing = false;
          this.error = error;
          this.$vToastify.error("Something went wrong please try again");
        })
    },

    setDistrictsToSelect() {
    const baseUrl = process.env.VUE_APP_BACKEND
    axiosApiInstance
      .get(baseUrl + `/api/v3/farmers/districts`)
      .then((result) => {
        if (result.data.success) {
          result.data.data.map(each => {
            this.districts.push(each.district);
          });
        } 
      })
      .catch(error => {
        this.error = error;
      })
    },
  },
};
</script>
