<template>
  <div>
    <b-navbar>
      <b-navbar-brand>AGENTS</b-navbar-brand>
      <b-navbar-nav class="ml-auto mr-2">
        <b-button squared variant="danger" v-b-modal.delete-agent 
        v-analytics:click="['delete_agent',{element:'Suppliers'}]"
        :disabled="!can('modify','suppliers')">
          Delete Agent
        </b-button>
        <b-button v-b-modal.agent-details squared variant="outline-primary" class="ml-2">
          View Info
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="container-fluid">
      <b-row class="page-controls border-bottom-0">
        <b-col >
          <div v-if="!isLoadingProfile||isEdited" class="d-flex">
            <div class="mr-4">
              <b-avatar 
                v-bind:src="'https://storage.googleapis.com/ezyagric-media' + agentsProfile.va_photo" 
                size="4rem"></b-avatar>
            </div>
            <div class="mt-3">
              <h2>{{agentsProfile.va_name}}</h2>
            </div>
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="col-auto text-right">
       <div class="sidebar-width text-right mt-3" v-show="false">

         <button  class="btn-opt primary">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
           <button class="btn-opt primary">
           <b-icon-printer></b-icon-printer> Print
           </button>
           <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button>
    

      </div>
      </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <div class="text-center" v-if="isLoadingProfile&&!isEdited">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
          <template v-if="!isLoadingProfile||isEdited">
            <div v-if="!filteredFarmersData.length" class="text-center">
              No farmers attached to this agent...
            </div>
            <div v-if="filteredFarmersData.length">
              <b-row>
                <b-col cols="8" class="mt-3">
                  {{agentsProfile.va_name}}'s Farmers
                </b-col>
                <b-col cols="4" class="mb-2">
                  <b-form>
                    <b-form-input
                      placeholder="Search Name"
                      type="search"
                      class="search"
                      v-model="searchfield"
                      @keyup="searchFarmers"
                      v-analytics:change="['search_agent_farmer',{element:'Suppliers'}]"
                    ></b-form-input>
                  </b-form>
                </b-col>
              </b-row>
              <b-table 
                :fields="fields" 
                :items="filteredFarmersData" 
                thead-class="bg-primary-tb text-primary-tb border-primary-tb"
              >
              <template #cell(farmer_name)="data">
                <b-link @click="getFarmerDetails(data.item)">
                {{data.item.farmer_name}}</b-link>
              </template>
              </b-table>
            </div>
          </template>
        </b-col>
        <b-col cols="4">
          <b-alert variant="info">
            <h6>Total Commission</h6>
            <h3>UGX 580,000</h3>
          </b-alert>
          <b-alert variant="info" show>
          <h6>Total Farmers</h6>
          <b-spinner v-if="isLoadingProfile&&!isEdited" small label="Small Spinner" variant="primary"></b-spinner>
          <h3>{{farmersCount}}</h3>
          </b-alert>
        </b-col>
      </b-row>
      <b-modal id="delete-agent" size="sm" hide-footer hide-header>
        <h5 class="text-center">Delete Item</h5>
        <p class="text-center">Do you want to delete this item?<br>You will not be able to recover it.</p>
        <div>
          <b-button type="reset" variant="secondary" class="center float-left" size="sm" 
          v-analytics:click="['cancel_agent_delete',{element:'Suppliers'}]"
          @click="onReset">Cancel</b-button>
          <b-button type="submit" variant="primary" class="ml-2 float-right" size="sm" 
          v-analytics:click="['confirm_agent_delete',{element:'Suppliers'}]"
          @click="deleteAgentProfile()">
            <b-spinner v-if="isDeleting" small label="Small Spinner"></b-spinner>
            Yes, Delete
          </b-button>
        </div>
      </b-modal>
      <agent-details :profile="agentsProfile" @IsEdited="onProfileEdited"></agent-details>
    </div>
        <profile-modal
    ></profile-modal>
  </div>
</template>

<script>
import { axiosApiInstance } from "@/plugins/axios";
import AgentDetails from "./components/agent_details/AgentDetails.vue";
import ProfileModal from '../../../../../components/farmer_modal/Farmer.vue';
import { bus } from '@/main.js';
import { can } from '../../../../auth/utils';

export default {
  components:{AgentDetails, ProfileModal},

  data() {
    let fields = [
      { key: "farmer_name", label: "Name" },
      { key: "farmer_phone_number", label: "Contact" },
      { key: "garden_acreage_mapped_gps", label: "Size" },
      { key: "value_chain", label: "Produce" },
      { key: "expected yield", label: "expected yield", thClass: 'd-none', tdClass: 'd-none' }
    ];
    return {
      fields,
      message: '',
      searchfield: '',
      error: '',
      isLoadingProfile: false,
      agentsFarmers: [],
      farmersCount: '',
      agentsProfile: {},
      agentId: '',
      isDeleting: false,
      isEdited: false,
      filteredFarmersData: [],
    };
  },

  computed: {
    projectId(){
      return this.$store.state.project_id;
    }
  },

  methods: {
    can,
    getFarmerDetails(dataObj) {
       bus.$emit('farmer-view', {
        name: dataObj.farmer_name,
        data: dataObj,
        farmer_id: dataObj.farmer_id,
        tab: 'profile',
      });
    },
    getAgentDetails(dataObj) {
      this.isLoadingProfile = true;
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/village-agents/single/${this.projectId}/${this.agentId}`)
        .then((result) => {
          this.isLoadingProfile = false;
          if (result.data.success) {
            dataObj.agentsFarmers = result.data.data.farmers;
            dataObj.filteredFarmersData = result.data.data.farmers;
            dataObj.agentsProfile = result.data.data.va_profile;
            dataObj.farmersCount = result.data.data.farmers_count;
          }
          this.isEdited = false;
        })
        .catch(error => {
          this.isLoadingProfile = false;
          this.error = error;
        })
    },

    onReset() {
      this.$bvModal.hide('delete-agent');
    },

    deleteAgentProfile() {
      this.isDeleting = true;
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .delete(baseUrl + `/api/v3/village-agents/delete/${this.projectId}/${this.agentId}`)
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.onReset();
            this.$router.push('/suppliers/agents');
          }
        })
        .catch(error => {
          this.isDeleting = false;
          this.error = error;
          this.$vToastify.error("Something went wrong please try again");
        })
    },

    onProfileEdited(status) {
      this.isEdited = status;
      this.getAgentDetails(this);
    },

    searchFarmers(){
      this.agentsFarmers ?
      this.filteredFarmersData = this.agentsFarmers.filter(
        data => data.farmer_name.toLowerCase()
        .indexOf(this.searchfield.toLowerCase())!== -1) :
      null;
    },
  },

  mounted() {
    this.agentId = this.$route.params.id;
    this.getAgentDetails(this);
    this.$analytics.track('view_agent',{element:'Suppliers'})
  },
};
</script>
  
<style>
</style>
