<template>
  <b-modal
    id="agent-details"
    size="lg"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    class="page-height-80"
  >
    <b-container>
      <b-row>
        <b-col class="ml-auto ">
          <h4 class="float-left">
            <b-icon icon="person-circle" class="mr-3" aria-hidden="true"></b-icon> {{ profile.va_name }}
          </h4>
        </b-col>
        
        <b-col class="col-sm-1 ml-auto">
          <button
            @click="$bvModal.hide('agent-details')"
            type="button"
            class="close float-right"
            aria-label="Close"
          >
           <b-icon-x/>
          </button>
        </b-col>
      </b-row>
      <hr />
    </b-container>

    <!-- Modal body -->
    <div class="row m-0">
      <!-- tabs -->
      <div class="col-2 left p-0 border-right">
        <div class="tabs" role="tablist">
          <a
            class="tab-link"
            :class="{ active: tab == 'bio' }"
            @click="tab = 'bio'"
            >Bio</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'identification' }"
            @click="tab = 'identification'"
            >Identification</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'services' }"
            @click="tab = 'services'"
            >Services</a
          >

        </div>
      </div>

      <!-- tab content -->
      <div class="col-10">
      <div class="modal-content-holder">
        <bio :item="profile" v-show="tab == 'bio'" @SuccesfulEdit="onEdited" :buyers="buyers"></bio>
        <identification :item="profile" v-show="tab == 'identification'" @SuccesfulEdit="onEdited"></identification>
        <services :item="profile" v-show="tab == 'services'" @SuccesfulEdit="onEdited"></services>
      </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Bio from "./subcomponents/bio/Bio.vue";
import Identification from "./subcomponents/identification/Identification.vue";
import Services from "./subcomponents/services/Services.vue";
import * as bapi from "../../../../buyers/api";

export default {
  name: "farmer-modal",
  props:{
    profile:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  components: {
    Bio,
    Identification,
    Services,
  },
  data() {
    return {
      name: "",
      tab: "bio",
      buyers:[]
    };
  },
  methods: {
    onEdited (status) {
      this.$emit('IsEdited', status);
    }
  },
  mounted() {
    bapi.blist().then(data=>{
      this.buyers = data.map(el=>({text:el.name,value:el.id}));
    })
  },
};
</script>
<style lang="css" scoped>
.tabs a {
  display: block;
  font-weight: normal;
  font-size: 1em;
  border-radius: 0pt;
  border-right: 4pt solid transparent;
  padding: 10pt 18pt;
  border-left: none;
  border-top: none;
  color: #666;
  cursor: pointer;
}

.tabs a.active {
  border-right-color: #3583c7;
  color: #3583c7;
  background-color: rgba(101, 110, 128, 0.1);
}

/* .page-height-80{
    width:80vh
} */
/* .left {
  background-color: rgba(230, 231, 233, 0.849);
} */
</style>
